<template>
  <div class="wrap" :style="{ '--height': mineHeight }">
    <van-nav-bar style="background-color: var(--navBg);height: calc(50rem / 16);">
      <template #right>
        <Lan />
      </template>
    </van-nav-bar>
    <div class="content">
      <div class="swiper">
        <van-swipe class="my-swipe" :autoplay="6000" indicator-color="white">
          <van-swipe-item style="
              height: calc(160rem / 16);
              background-color: rgba(158, 166, 213, 0.46);
              width: 100%;
            " v-if="islocalImg"></van-swipe-item>
          <div v-for="(item, index) in banner" :key="index">
            <van-swipe-item v-if="!islocalImg">
              <img class="imgs" :src="`${item.picture}`" alt="" />
            </van-swipe-item>
          </div>
        </van-swipe>
      </div>
      <div class="grid">
        <van-grid :column-num="2" gutter="10px" :border="false">
          <van-grid-item v-for="(item, index) in gridItem" :key="index" class="grid_item">
            <div class="item" @click="item.callBack()">
              <img :src="item.icon" alt="">
              <span class="item_text" :style="{ color: item.color || '#010101' }">{{ $t('newTabbar.goodsPage.' +
                item.text) }}</span>
            </div>
          </van-grid-item>
        </van-grid>
      </div>
      <div class="content-title">{{ $t('newTabbar.goodsPage.contentTitle') }}</div>
      <div class="shop">
        <van-grid :column-num="3" gutter="10px" :border="false">
          <van-grid-item v-for="(item, index) in (shopArr.length > 0 ? shopArr : 6)" :key="index" class="grid_item">
            <div class="item" @click="gameClick(item)">
              <img v-if="item.icon" :src="$img(item.icon)" alt="" style="height: calc(70rem / 16);">
            </div>
          </van-grid-item>
        </van-grid>
      </div>
      <div class="content-title">{{ $t('newTabbar.goodsPage.dynamic') }}</div>
      <div class="marquee_container">
        <div class="marquee" :class="scrollFlag ? 'adim' : ''" :style="{ marginTop: scrollTop }">
          <div v-for="item, index in displayedData" :key="index" class="marquee_item">
            <span class="item_left">{{ dataTime }}</span>
            <div class="item_right">
              <span>{{ $t('newTabbar.goodsPage.commission', { icon: moneyIcon, text: $formatNumber(item.money) }) }}</span>
              <span class="item_name">{{ item.name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="grid">
        <van-grid :column-num="2" gutter="10px" :border="false">
          <van-grid-item v-for="(item, index) in grid2Item" :key="index" class="grid_item">
            <div class="item" @click="gridClick(item)">
              <img v-if="item.picture" :src="item.picture && item.picture[0]" alt="">
              <span class="item_text" :style="{ color: item.color || '#010101' }">
                <!-- {{ $t('newTabbar.goodsPage.' + item.text) }} -->
                {{ item.title }}
              </span>
            </div>
          </van-grid-item>
        </van-grid>
      </div>
    </div>

    <!-- <div class="myself">
      <div class="content">
        <div class="info">
          <div class="left">
            <img v-if="typeof userInfo.level == 'number'" :src="require(`../../assets/icon/vip-icons/${Number(userInfo.level)
              }.png`)
              " alt="" />
            <img v-else src="../../assets/icon/vip-icons/1.png" alt="" />
          </div>
          <div class="right">
            <div class="top">
              <div class="user">
                {{
                  userInfo.username
                  ? userInfo.username
                  : $t("newAdd.noLoginUserName")
                }}
              </div>
            </div>
            <div class="balance">
              <span v-if="country != 'tr'">{{ verConfig.currency }}</span>
              <span v-if="country == 'tr'">TRY</span>
              {{ userInfo.money ? userInfo.money : "0" }}
            </div>
          </div>
        </div>
        <div class="btn" @click="toWithdraw">
          {{ $t("newAdd.Withdraw") }}
        </div>
        <div class="btn" @click="toRecharge">
          {{ $t("newAdd.Recharge") }}
        </div>
      </div>
    </div>
    <div class="game_banner" @click="gameClick">
      <img :src="require('@/assets/home/game_banner.png')" alt="">
    </div>
    <div class="content">
      <div class="left_bar">
        <ul>
          <li v-for="(item, index) in inimenuArr" :key="index" class="menuBar" @click="menuChange(index)">
            <img :src="menuVal === index ? item.activeImg : item.img" alt="" />
            <div :style="{
              '--color': menuVal === item.value ? '#fff' : '',
            }">
              {{ item.title }}
            </div>
          </li>
          <li v-for="(item, index) in menuArr" :key="index + 3" class="menuBar" @click="menuChange(index + 3)">
            <img :src="menuVal === index + 3 ? item.picture[1] : item.picture[0]" alt="" />
            <div :style="{
              '--color': menuVal === item.value ? '#fff' : '',
            }">
              {{ item.title }}
            </div>
          </li>
        </ul>
      </div>
      <div class="right_bar">
        <ul v-if="menuVal == 0">
          <li v-for="(item, index) in levels" :key="index" @click="toRushOrder(index)">
            <div class="grade_title">{{ item.title }}</div>
            <div class="img">
              <img :src="require(`../../assets/Img/VIPLOGO/VIP-${index % 9 + 1}.png`)" alt="" />
            </div>
            <div class="lock" v-if="typeof userInfo.level == 'number'
              ? index > userInfo.level
              : true
              " @click.stop="toUnlock()">
              <img src="../../assets/icon/lock.png" alt="" />
            </div>
          </li>
        </ul>
        <div class="help_wrap" v-if="menuVal == 1">
          <van-collapse v-model="activeNames" accordion>
            <van-collapse-item v-for="(item, index) in helpData" :key="index" :title="item.title" :name="index">{{
              item.content }}
            </van-collapse-item>
          </van-collapse>
        </div>
        <div class="invite_wrap" v-if="menuVal == 2">
          <img src="../../assets/icon/invite_banner.jpg" alt="" />
          <ul>
            <li>
              <p class="iconfont">&#xe60f;</p>
              <p>First</p>
              <p>level:0.0000%</p>
            </li>
            <li>
              <p class="iconfont">&#xe60f;</p>
              <p>Level</p>
              <p>2:0.0000%</p>
            </li>
            <li style="margin-right: 0">
              <p class="iconfont">&#xe60f;</p>
              <p>Level</p>
              <p>3:0.0000%</p>
            </li>
          </ul>
          <div class="code_link">
            <div class="text_wrap">
              <div class="title">{{ $t("newAdd.referralLink") }}</div>
              <div class="code_num">
                <div class="text">{{ shareUrl }}</div>
                <div class="btn" v-clipboard:copy="shareUrl" v-clipboard:success="copySuccess"
                  v-clipboard:error="copyError">
                  {{ $t("newAdd.copy") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="else_wrap" v-show="menuVal === index + 3" v-for="(item, index) in menuArr" :key="index"
          v-html="item.content"></div>
      </div>
    </div> -->
    <Tabbar active="goods" />
    <!-- <LoadingRE :show="true"></LoadingRE> -->
  </div>
</template>

<script>
import Tabbar from "@/components/Tabbar";
import LoadingRE from "@/components/LoadingRE";
import VueClipboard from "vue-clipboard2";
import { Toast } from "vant";
import Cookies from "js-cookie";
import { GET_CONFIG_INFO, GRID_ARR, INFOR_ARR } from "@/api";
import { mapGetters } from "vuex";
import { Swipe, SwipeItem, Collapse, CollapseItem, NavBar, Grid, GridItem } from "vant";
import { getToken } from "@/utils/tools";
import Lan from '@/components/lan';
export default {
  components: {
    Tabbar,
    LoadingRE,
    VueClipboard,
    Toast,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [NavBar.name]: NavBar,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    Lan,
    Lan
  },
  data() {
    return {
      mineHeight: '50rem',
      // islocalImg: false,
      // userInfo: {},
      country: Cookies.get("language"),
      bannerArr: [
        // '../../assets/Img/banner/banner (1).png',
        // '../../assets/Img/banner/banner (2).png',
      ],
      noticeArr: [],
      // [
      //   'Welcome to Y88 club',
      //   'the withdrawal time is from 9 am to 9 PM',
      //   'the club will update the charging account from time to time',
      //   'the account above the store shall prevail',
      //   'please inform each other',
      //   'thank you for your cooperation!',
      // ],
      inimenuArr: [
        {
          // title: 'Mission hall',
          title: this.$t("newAdd.missionHall"),
          img: require("../../assets/icon/1.png"),
          activeImg: require("../../assets/icon/1-active.png"),
          // value: 1,
        },
        {
          // title: 'Help center',
          title: this.$t("newAdd.helpCenter"),
          img: require("../../assets/icon/3.png"),
          activeImg: require("../../assets/icon/3-active.png"),
          // value: 2,
        },
        {
          // title: 'Invite friends',
          title: this.$t("newAdd.inviteFriends"),
          img: require("../../assets/icon/7.png"),
          activeImg: require("../../assets/icon/7-active.png"),
          // value: 3,
        },
      ],
      menuArr: [],
      menuVal: 0,
      allowed: 0,
      gradeArr: [
        {
          title: "",
          rate: "",
        },
        {
          title: "",
          rate: "",
        },
        {
          title: "",
          rate: "",
        },
        {
          title: "",
          rate: "",
        },
      ],
      videoArr: [
        {
          title: "video1",
        },
        {
          title: "video2",
        },
        {
          title: "video3",
        },
        {
          title: "video4",
        },
      ],
      helpData: [
        // {
        //   title: this.$t('newAdd.help1Title'),
        //   context: this.$t('newAdd.help1Context')
        // },
        // {
        //   title: this.$t('newAdd.help2Title'),
        //   context: this.$t('newAdd.help2Context')
        // },
        // {
        //   title: this.$t('newAdd.help3Title'),
        //   context: this.$t('newAdd.help3Context')
        // }
      ],
      activeNames: 0,
      service_url: "",
      loadingNum: 0,
      gridItem: [{
        icon: require('@/assets/Img/goods/img/grid1.png'),
        url: '',
        color: '#8188fe',
        text: 'topUp',
        callBack: () => {
          if (getToken()) {
            this.$router.push("new-recharge");
          } else {
            this.$toast(this.$t("newAdd.NotLogin"));
          }
        }
      }, {
        icon: require('@/assets/Img/goods/img/grid2.png'),
        url: '',
        color: '#fb9833',
        text: 'withdraw',
        callBack: () => {
          if (getToken()) {
            this.$router.push("new-withdraw");
          } else {
            this.$toast(this.$t("newAdd.NotLogin"));
          }
        }
      }, {
        icon: require('@/assets/Img/goods/img/grid3.png'),
        url: '',
        color: '#3ac07f',
        text: 'invite',
        callBack: () => {
          if (getToken()) {
            this.$router.push("/Invite");
          } else {
            this.$toast(this.$t("newAdd.NotLogin"));
          }
        }
      }, {
        icon: require('@/assets/Img/goods/img/grid4.png'),
        url: '',
        color: '#47a7fd',
        text: 'download',
        callBack: () => { }
      }],
      dataList: [
        {
          "img": "http://api.matchamazon.net/storage/topic/20230621/f9882048a8677e7dc479c0fc0f3e18cd.jpg",
          "title": "Cosmetics",
          "name": "fac***",
          "money": "486.00",
          "time": 5
        },
        {
          "img": "http://api.matchamazon.net/storage/topic/20230621/e6dc8eeb544119d61a298b7b6349a660.jpg",
          "title": "Watches",
          "name": "855***",
          "money": "125487.00",
          "time": 1
        },
        {
          "img": "http://api.matchamazon.net/storage/topic/20230621/e773794bee41ccc32d7eb9f88da87636.jpg",
          "title": "Fitness",
          "name": "495***",
          "money": "271578.00",
          "time": 3
        },
        {
          "img": "http://api.matchamazon.net/storage/topic/20230621/f9882048a8677e7dc479c0fc0f3e18cd.jpg",
          "title": "Cosmetics",
          "name": "772***",
          "money": "14237.00",
          "time": 4
        },
        {
          "img": "http://api.matchamazon.net/storage/topic/20230621/1b7e213743babcb83a5edc680c445d6e.jpg",
          "title": "Phone",
          "name": "449***",
          "money": "8621.00",
          "time": 1
        },
        {
          "img": "http://api.matchamazon.net/storage/topic/20230621/9025558a5323c52d5d604daa59781129.jpg",
          "title": "Pet Supplies",
          "name": "011***",
          "money": "158.00",
          "time": 3
        },
        {
          "img": "http://api.matchamazon.net/storage/topic/20230621/a4a2337a280752c4f11420306347afcc.jpg",
          "title": "Digital",
          "name": "319***",
          "money": "5436.00",
          "time": 4
        },
        {
          "img": "http://api.matchamazon.net/storage/topic/20230621/e773794bee41ccc32d7eb9f88da87636.jpg",
          "title": "Fitness",
          "name": "d15***",
          "money": "32595.00",
          "time": 5
        },
        {
          "img": "http://api.matchamazon.net/storage/topic/20230621/a4a2337a280752c4f11420306347afcc.jpg",
          "title": "Digital",
          "name": "136***",
          "money": "17352.00",
          "time": 1
        },
        {
          "img": "http://api.matchamazon.net/storage/topic/20230621/e773794bee41ccc32d7eb9f88da87636.jpg",
          "title": "Fitness",
          "name": "f9c***",
          "money": "493512.00",
          "time": 2
        },
        {
          "img": "http://api.matchamazon.net/storage/topic/20230621/c7f757084b9f9d9497ce75d570d05c3b.jpg",
          "title": "Luxury",
          "name": "659***",
          "money": "3254.00",
          "time": 1
        },
        {
          "img": "http://api.matchamazon.net/storage/topic/20230621/9025558a5323c52d5d604daa59781129.jpg",
          "title": "Pet Supplies",
          "name": "450***",
          "money": "679.00",
          "time": 4
        },
        {
          "img": "http://api.matchamazon.net/storage/topic/20230621/c7f757084b9f9d9497ce75d570d05c3b.jpg",
          "title": "Luxury",
          "name": "857***",
          "money": "8862.00",
          "time": 3
        },
        {
          "img": "http://api.matchamazon.net/storage/topic/20230621/f6103b257f2722bc416ce3f485ed5f16.jpg",
          "title": "Furniture",
          "name": "595***",
          "money": "158.00",
          "time": 4
        },
        {
          "img": "http://api.matchamazon.net/storage/topic/20230621/e6dc8eeb544119d61a298b7b6349a660.jpg",
          "title": "Watches",
          "name": "ac7***",
          "money": "14586.00",
          "time": 2
        },
        {
          "img": "http://api.matchamazon.net/storage/topic/20230621/e773794bee41ccc32d7eb9f88da87636.jpg",
          "title": "Fitness",
          "name": "189***",
          "money": "271578.00",
          "time": 3
        },
        {
          "img": "http://api.matchamazon.net/storage/topic/20230621/e6dc8eeb544119d61a298b7b6349a660.jpg",
          "title": "Watches",
          "name": "752***",
          "money": "18895.00",
          "time": 4
        },
        {
          "img": "http://api.matchamazon.net/storage/topic/20230621/e773794bee41ccc32d7eb9f88da87636.jpg",
          "title": "Fitness",
          "name": "121***",
          "money": "58.00",
          "time": 5
        },
        {
          "img": "http://api.matchamazon.net/storage/topic/20230621/f9882048a8677e7dc479c0fc0f3e18cd.jpg",
          "title": "Cosmetics",
          "name": "1fe***",
          "money": "3694.00",
          "time": 4
        },
        {
          "img": "http://api.matchamazon.net/storage/topic/20230621/e6dc8eeb544119d61a298b7b6349a660.jpg",
          "title": "Watches",
          "name": "db3***",
          "money": "1010.00",
          "time": 1
        },
        {
          "img": "http://api.matchamazon.net/storage/topic/20230621/1b7e213743babcb83a5edc680c445d6e.jpg",
          "title": "Phone",
          "name": "646***",
          "money": "8862.00",
          "time": 3
        },
        {
          "img": "http://api.matchamazon.net/storage/topic/20230621/386492f0d1485c1bbf855442b4f2ce53.jpg",
          "title": "Electrical",
          "name": "366***",
          "money": "271578.00",
          "time": 3
        },
        {
          "img": "http://api.matchamazon.net/storage/topic/20230621/386492f0d1485c1bbf855442b4f2ce53.jpg",
          "title": "Electrical",
          "name": "008***",
          "money": "811.00",
          "time": 2
        },
        {
          "img": "http://api.matchamazon.net/storage/topic/20230621/c7f757084b9f9d9497ce75d570d05c3b.jpg",
          "title": "Luxury",
          "name": "f2e***",
          "money": "3694.00",
          "time": 2
        },
        {
          "img": "http://api.matchamazon.net/storage/topic/20230621/f6103b257f2722bc416ce3f485ed5f16.jpg",
          "title": "Furniture",
          "name": "750***",
          "money": "15782.00",
          "time": 5
        },
        {
          "img": "http://api.matchamazon.net/storage/topic/20230621/f9882048a8677e7dc479c0fc0f3e18cd.jpg",
          "title": "Cosmetics",
          "name": "724***",
          "money": "8621.00",
          "time": 5
        },
        {
          "img": "http://api.matchamazon.net/storage/topic/20230621/f6103b257f2722bc416ce3f485ed5f16.jpg",
          "title": "Furniture",
          "name": "1e4***",
          "money": "18465.00",
          "time": 2
        },
        {
          "img": "http://api.matchamazon.net/storage/topic/20230621/e773794bee41ccc32d7eb9f88da87636.jpg",
          "title": "Fitness",
          "name": "759***",
          "money": "15896.00",
          "time": 2
        }
      ],
      displayedData: [],
      scrollInterval: null,
      currentIndex: 0,
      scrollTop: '0',
      scrollFlag: false,
      moneyIcon: 'IDR',
      // grid2Item: [{
      //   icon: require('@/assets/Img/goods/grid/grid1.png'),
      //   url: '/Introduction',
      //   text: 'Introduction'
      // }, {
      //   icon: require('@/assets/Img/goods/grid/grid2.png'),
      //   url: '/Rule',
      //   text: 'illustrate'
      // }, {
      //   icon: require('@/assets/Img/goods/grid/grid3.png'),
      //   url: '/Cooperate',
      //   text: 'cooperate'
      // }, {
      //   icon: require('@/assets/Img/goods/grid/grid4.png'),
      //   url: '/Qualification',
      //   text: 'qualification'
      // }],
      // shopArr: [],
      dataTime: this.getTime()
    };
  },
  methods: {
    getTime() {
      const currentDate = new Date();
      let currentMonth = currentDate.getMonth() + 1;
      if (currentMonth < 10) currentMonth = '0' + currentMonth
      let currentDay = currentDate.getDate();
      if (currentDay < 10) currentDay = '0' + currentDay;
      return currentMonth + '-' + currentDay
    },
    // async getGridArr() {
    //   this.loadingNum++
    //   try {
    //     const res = await GRID_ARR();
    //     this.loadingNum--;
    //     this.shopArr = res.data
    //   } catch (error) {
    //     this.loadingNum--
    //     console.error(error);
    //   }
    // },
    // async getInfoArr() {
    //   this.loadingNum++
    //   try {
    //     const res = await INFOR_ARR();
    //     this.loadingNum--;
    //     this.grid2Item = res.data?.lists;
    //     this.grid2Item[0].url = '/Introduction';
    //     this.grid2Item[1].url = '/Rule';
    //     this.grid2Item[2].url = '/Cooperate';
    //     this.grid2Item[3].url = '/Qualification';
    //   } catch (error) {
    //     this.loadingNum--
    //     console.error(error);
    //   }
    // },
    getDownloadUrl() {
      this.loadingNum++;
      this.$api.getNotice((res) => {
        this.loadingNum--;
        this.gridItem[3].callBack = () => {
          window.location.href = res?.appurl_android || '';
        }
      })
    },
    gridClick(item) {
      this.$router.push(item.url + '?id=' + item.id)
    },
    gameClick(item) {
      if(this.userInfo.disable_lottery == 1) return Toast(this.$t('gameToast'));
      item.icon && this.$router.push(`/games?code=${item.code}`)
    },
    // toWithdraw(){
    //   this.$router.push({name: 'withdrawList'})
    // },
    toInvite() {
      this.$router.push({ name: "InviteFriends" });
    },
    toRecharge() {
      this.$router.push({ name: "NewRecharge" });
    },
    toService() {
      // this.$api.toService();
    },
    toWithdraw() {
      this.$router.push({ name: "newWithdraw" });
    },
    toService() {
      // window.location.href = this.service_url
    },
    copySuccess() {
      // Toast('Copy success!')
      Toast(this.$t("newAdd.copySuccess"));
    },
    copyError() {
      // Toast('Copy the failure!')
      Toast(this.$t("newAdd.copyError"));
    },
    menuChange(val) {
      this.menuVal = val;
    },
    handleChange(val) { },
    // 获取等级数组
    getLevels() {
      this.$api.getLevel((res) => {
        this.loadingNum--;
        this.gradeArr = res;
      });
    },
    // 获取用户信息
    // getUserInfo() {
    //   // Toast.loading({
    //   //   forbidClick: true,
    //   //   duration: 0,
    //   // })
    //   this.loadingNum++;
    //   if (!this.userInfo.level) {
    //     this.$api.getUserInfo(
    //       (res) => {
    //         if (res.identity.indexOf("agent") == -1) {
    //           this.inimenuArr.splice(2, 1);
    //         }
    //         this.userInfo = res;
    //         this.loadingNum--;
    //         // Toast.clear()
    //       },
    //       (err) => {
    //         // Toast.clear()
    //         this.loadingNum--;
    //       }
    //     );
    //   }
    // },
    // 等级跳转
    toRushOrder(index) {
      this.$router.push({ name: "MakeMoney" });
      // this.$router.push({
      //   name: 'cart',
      //   params: {
      //     level: index,
      //   },
      // })
    },
    toUnlock() {
      this.$router.push({ name: "levelUpgrade" });
    },
    // getNotice(){
    //   this.$api.getNotice(res=>{
    //     this.noticeArr = (res.scroll_notice.split(","));
    //     this.service_url = res.service_url;
    //     this.$cookie.set('service_url',res.service_url);
    getNotice() {
      this.$api.getNotice((res) => {
        // this.noticeArr = res.scroll_notice.split(',')
        this.noticeArr.push(res.scroll_notice);
        this.service_url = res.service_url;
      });
    },
    getBanner() {
      this.loadingNum++;
      this.$api.getBanner(
        (res) => {
          this.loadingNum--;
          if (res.length) {
            this.bannerArr = res;
            this.islocalImg = false;
          }
        },
        (err) => {
          this.loadingNum--;
        }
      );
    },
    getConfigShare() {
      this.loadingNum++;
      GET_CONFIG_INFO({
        act: "share",
      })
        .then((r) => {
          this.loadingNum--;
          if (r.data.ret === 1) {
            this.inviteLink = r.data.data.text;
            this.$cookie.set("public-share-item", JSON.stringify(r.data.data));
          } else {
            this.inviteLink = "";
            this.$cookie.set("public-share-item", {});
          }
        })
        .catch((e) => {
          console.error(e);
          this.loadingNum--;
        });
    }, // 获取分享地址
    getMenuArr() {
      this.loadingNum++;
      this.$api.getHomeConfig(
        (res) => {
          this.loadingNum--;
          this.menuArr = res.lists;
        },
        (err) => {
          this.loadingNum--;
        }
      );
    },
    getHelpArr() {
      this.loadingNum++;
      this.$api.getHomeHelp(
        (res) => {
          this.loadingNum--;
          for (let i = 0; i < res.lists.length; i++) {
            let res_item = res.lists[i];
            res_item.content = res_item.content.replace(/<[^>]*>/gi, "");
          }
          this.helpData = res.lists;
        },
        (err) => {
          this.loadingNum--;
        }
      );
    },
    // 滚动
    startScrolling() {
      this.scrollInterval = setInterval(() => {
        setTimeout(() => {
          this.scrollTop = '0';
          this.scrollFlag = false;
        }, 0)
        setTimeout(() => {
          this.scrollFlag = true;
          this.scrollTop = 'calc(-88rem / 16)'
        }, 500);
        setTimeout(() => {
          this.scrollUp()
        }, 0)
      }, 1000);
    },
    scrollUp() {
      if (this.currentIndex === this.dataList.length - 1) {
        this.currentIndex = 0;
      } else {
        this.currentIndex++;
      }
      this.updateVisibleData();
    },
    updateVisibleData() {
      this.displayedData = this.dataList.slice(this.currentIndex).concat(this.dataList.slice(0, this.currentIndex));
    }
  },
  created() {
    Toast.loading({
      forbidClick: true,
      duration: 0,
    });
    // if (getToken()) {
    //   this.getUserInfo();
    // }
    // this.getLevels();
    // this.getNotice();
    // this.getBanner();
    // this.getConfigShare();
    this.$store.dispatch('getUserInfo');
    this.getMenuArr();
    this.getHelpArr();
    this.getDownloadUrl();
    this.$store.dispatch("updateTaskUserInfo");
    // this.getGridArr()
    // this.getInfoArr();
  },
  mounted() {
    if (!this.$cookie.get("token")) {
      this.inimenuArr.splice(2, 1);
    }
    this.startScrolling();
  },
  computed: {
    ...mapGetters(["userInfo", "levels", "banner", "shareUrl", "systemConfig","grid2Item","shopArr"]),
    islocalImg() {
      if (!this.banner.length) return true;
      return false;
    },
  },
  beforeDestroy() {
    clearInterval(this.scrollInterval);
  },
  destroyed() { },
  watch: {
    loadingNum: (newVal) => {
      if (!newVal) {
        setTimeout(() => {
          Toast.clear();
        }, 1000);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
  margin: 0 auto;
  min-height: calc(100vh - 50px);
  background-color: #f4f8fb;

  /deep/ .van-nav-bar__content {
    height: 100%;
  }

  .content {
    padding: 0 calc(20rem / 16);
    padding-bottom: calc(5rem / 16);

    .top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      >.logo {
        >img {
          height: calc(28rem / 16);
          // height: 100%;
        }
      }

      >.msg {
        font-size: calc(32rem / 16);
        color: #eaad5f;
      }
    }

    >.swiper {
      padding: calc(4rem / 16) 0;

      .my-swipe .van-swipe-item {
        width: 100%;
        color: #fff;
        font-size: 20px;
        height: calc(194rem / 16);
        text-align: center;
        background-color: transparent;
        border-radius: 10px;

        .imgs {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }
    }

    .grid {
      margin-top: calc(10rem / 16);

      /deep/ .van-grid {
        padding-left: 0 !important;
      }

      .grid_item {
        /deep/ .van-grid-item__content {
          // background-color: #f4f8fb;
          align-items: normal;
          border-radius: calc(8rem / 16);
        }

        .item {
          display: flex;
          align-items: center;

          img {
            width: calc(40rem / 16);
            height: calc(40rem / 16);
            display: block;
            margin-right: calc(12rem / 16);
          }

          span {
            font-size: calc(12rem / 16);
          }
        }
      }

      .grid_item:nth-child(2n) {
        padding-right: 0 !important;
      }
    }

    .content-title {
      font-size: calc(14rem / 16);
      padding: calc(16rem / 16) 0 calc(8rem / 16);
    }

    .shop {
      /deep/ .van-grid {
        padding-left: 0 !important;
      }

      .grid_item {
        /deep/ .van-grid-item__content {
          padding: 0;
          border-radius: calc(8rem / 16);
        }

        .item {
          img {
            width: 100%;
            height: 100%;
            display: block;
            margin-right: calc(12rem / 16);
          }
        }
      }

      .grid_item:nth-child(3n) {
        padding-right: 0 !important;
      }
    }

    .marquee_container {
      height: calc(248rem / 16);
      overflow: hidden;

      .marquee {
        display: flex;
        flex-direction: column;

        .marquee_item {
          height: calc(72rem / 16);
          border-radius: calc(12rem / 16);
          margin-bottom: calc(16rem / 16);
          background-color: #fff;
          display: flex;
          box-shadow: 0 2px 4px #dedede;

          .item_left {
            width: 25%;
            height: 100%;
            border-right: 1px solid rgba(0, 0, 0, .12);
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .item_right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 calc(16rem / 16);

            span {
              color: #010101;
              font-size: calc(14rem / 16);
              line-height: 1.6;
            }

            .item_name {
              color: #999;
              font-weight: 100;
              line-height: 1.5;
            }
          }
        }
      }

      .adim {
        transition: all .5s;
      }

      @keyframes marqueeAnimation {
        0% {
          margin-top: 0;
        }

        100% {
          margin-top: calc(-88rem / 16)
        }
      }
    }
  }




  // >.myself {
  //   .content {
  //     padding: calc(10rem / 16) calc(8rem / 16);
  //     border: solid 1px #f2ddc6;
  //     margin-top: calc(7rem / 16);
  //     background-color: #fff;
  //     border-radius: 6px;
  //     display: flex;
  //     justify-content: space-between;
  //     align-items: center;

  //     >.info {
  //       // width: calc(99rem / 16);
  //       height: calc(33rem / 16);
  //       display: flex;

  //       >.left {
  //         width: calc(33rem / 16);
  //         height: 100%;
  //         margin-right: calc(3rem / 16);
  //         background-color: #cfa17b;
  //         border-radius: 2px;

  //         >img {
  //           height: 100%;
  //           border-radius: 2px;
  //         }
  //       }

  //       >.right {
  //         >.top {
  //           display: flex;

  //           >.user {
  //             font-size: calc(12rem / 16);
  //             color: #9298aa;
  //             margin-right: 3px;
  //           }

  //           >.grade {
  //             width: calc(33rem / 16);
  //             height: calc(14rem / 16);
  //             background: url("../../assets/icon/vipSpeeds.png");
  //             background-size: calc(33rem / 16);
  //             background-position: 0 var(--top);
  //           }
  //         }
  //       }

  //       // > .top {
  //       //   display: flex;
  //       //   > .user {
  //       //     font-size: calc(12rem / 16);
  //       //     color: #9298aa;
  //       //     margin-right: 3px;
  //       //   }
  //       //   > .grade {
  //       //     width: calc(33rem / 16);
  //       //     height: calc(14rem / 16);
  //       //     background: url('../../assets/icon/vipSpeeds.png');
  //       //     background-size: calc(33rem / 16);
  //       //     background-position: 0 var(--top);
  //       //   }
  //       // }
  //       >.balance {
  //         font-size: calc(17rem / 16);
  //         font-weight: 700;
  //         color: #424855;
  //         line-height: 1;
  //         margin-top: 2px;
  //       }
  //     }

  //     >.btn {
  //       width: calc(99rem / 16);
  //       height: calc(33rem / 16);
  //       background-image: url("../../assets/icon/btn_bg.png");
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //       background-size: 100% 100%;
  //       font-size: calc(13rem / 16);
  //       color: #fff;
  //       font-weight: bold;
  //     }
  //   }
  // }

  // .game_banner {
  //   // height: calc(120rem / 16);
  //   overflow: hidden;
  //   margin-top: calc(11rem / 16);

  //   img {
  //     width: 100%;
  //     border-radius: calc(8rem / 16);
  //   }
  // }

  // >.content {
  //   display: flex;
  //   margin-top: calc(11rem / 16);

  //   >.left_bar {
  //     >ul {
  //       >li {
  //         width: calc(60rem / 16);
  //         height: calc(53rem / 16);
  //         margin-bottom: 20px;
  //         position: relative;

  //         >img {
  //           width: 100%;
  //           position: absolute;
  //           z-index: -1;
  //           top: 0;
  //         }

  //         >div {
  //           color: var(--color, #8f9bb1);
  //           text-align: center;
  //           position: relative;
  //           top: calc(30rem / 16);
  //           line-height: 1;
  //           font-size: calc(10rem / 16);
  //         }
  //       }
  //     }
  //   }

  //   >.right_bar {
  //     flex-grow: 1;
  //     margin-left: calc(9rem / 16);

  //     >ul {
  //       width: 100%;
  //       display: flex;
  //       flex-wrap: wrap;

  //       >li {
  //         width: 48%;
  //         // height: calc(169rem / 16);
  //         background-image: url("../../assets/icon/grade_bg.png");
  //         background-size: 100% 100%;
  //         margin-right: 4%;
  //         margin-bottom: 4%;
  //         border-radius: 4px;
  //         position: relative;

  //         >.grade_title {
  //           width: 100%;
  //           line-height: 20px;
  //           text-align: center;
  //           position: relative;
  //           color: #7980a7;
  //         }

  //         >.img {
  //           width: 100%;
  //           margin-bottom: 25px;
  //           display: flex;
  //           justify-content: center;

  //           >img {
  //             // width: 80%;
  //             width: 30vw;
  //             height: 32vw;
  //             max-width: 100%;
  //             margin-bottom: 10px;
  //           }
  //         }

  //         >.rebate_wrap {
  //           color: #fff;
  //           position: absolute;
  //           left: 3px;
  //           bottom: 4px;

  //           .top {
  //             font-size: calc(8rem / 16);
  //             line-height: 1;
  //           }

  //           .rebate {
  //             font-size: calc(16rem / 16);
  //             line-height: 1;
  //           }
  //         }

  //         >.orderNum {
  //           position: absolute;
  //           right: 3px;
  //           bottom: 4px;
  //           font-size: calc(9rem / 16);
  //           color: #7980a7;
  //         }

  //         >.lock {
  //           width: 100%;
  //           height: 100%;
  //           position: absolute;
  //           background-color: rgba(158, 166, 213, 0.46);
  //           display: flex;
  //           justify-content: center;
  //           align-items: center;
  //           border-radius: 4px;
  //           top: 0;
  //           left: 0;
  //           z-index: 9;

  //           >img {
  //             width: calc(38rem / 16);
  //             height: calc(46rem / 16);
  //           }
  //         }
  //       }

  //       >li:nth-child(2n) {
  //         margin-right: 0;
  //       }
  //     }

  //     >.video_wrap {
  //       width: 100%;
  //       border-radius: 6px;

  //       >.video_item {
  //         width: 100%;
  //         height: calc(93rem / 16);
  //         background-color: #f2f2f2;
  //         margin-bottom: calc(11rem / 16);
  //         border-radius: 8px;
  //         overflow: hidden;
  //         position: relative;
  //         background-repeat: no-repeat;
  //         background-size: 100% 100%;

  //         >.title {
  //           color: #c9a26f;
  //           font-size: calc(16rem / 16);
  //           line-height: 1;
  //           font-weight: 700;
  //           margin: calc(16rem / 16) 0 0 calc(16rem / 16);
  //         }

  //         >.playBtn {
  //           position: absolute;
  //           left: calc(16rem / 16);
  //           bottom: calc(16rem / 16);
  //           width: calc(85rem / 16);
  //           height: calc(25rem / 16);
  //           background-color: #c9a26f;
  //           font-size: calc(12rem / 16);
  //           font-weight: 700;
  //           text-align: center;
  //           color: #fff;
  //           line-height: calc(24rem / 16);
  //           box-shadow: 0px 1px 3px 0px rgb(164 122 39 / 44%);
  //           border-radius: calc(12rem / 16);
  //         }
  //       }
  //     }

  //     >.invite_wrap {
  //       width: 100%;

  //       >img {
  //         width: 100%;
  //       }

  //       >ul {
  //         display: flex;

  //         >li {
  //           width: 33%;
  //           margin-right: 1px;
  //           background: rgba(220, 231, 252, 0.6);
  //           padding: 4px 0;
  //           border-radius: 2px;

  //           >p {
  //             border-radius: 2px;
  //             text-align: center;
  //             line-height: 1.2;
  //             font-size: calc(12rem / 16);
  //             color: #7c83a9;
  //           }

  //           >p:nth-child(1) {
  //             font-size: calc(20rem / 16);
  //           }
  //         }
  //       }

  //       >.invite_btn {
  //         margin-top: calc(5rem / 16);
  //         width: 100%;
  //         display: flex;
  //         justify-content: center;

  //         >button {
  //           // width: calc(62rem / 16);
  //           padding: calc(8rem / 16) calc(20rem / 16);
  //           background-color: #eaad5f;
  //           border-radius: 5px;
  //           text-align: center;
  //           // line-height: calc(25rem / 16);
  //           font-size: calc(16rem / 16);
  //           font-weight: 600;
  //           color: #fff;
  //           justify-content: flex-end;
  //           outline: none;
  //           border: none;
  //         }
  //       }

  //       >.code_link {
  //         max-width: 100%;
  //         margin-top: 13px;
  //         display: flex;
  //         justify-content: space-between;
  //         background: rgba(220, 231, 252, 0.6);
  //         align-items: center;
  //         border-radius: 4px;

  //         >.text_wrap {
  //           width: 100%;
  //           display: flex;
  //           flex-wrap: nowrap;

  //           // overflow: auto;
  //           >.title {
  //             font-size: calc(12rem / 16);
  //             color: #9da7c0;
  //             margin-left: 4px;
  //             // white-space: nowrap;
  //             flex-shrink: 0;
  //             line-height: calc(25rem / 16);
  //           }

  //           >.code_num {
  //             text-indent: calc(5rem / 16);
  //             flex-grow: 1;
  //             flex-shrink: 1;
  //             // max-width: 60%;
  //             font-size: calc(14rem / 16);
  //             color: #cfa17b;
  //             display: flex;
  //             justify-content: flex-end;
  //             align-items: center;
  //             position: relative;

  //             >.text {
  //               position: absolute;
  //               left: 0;
  //               max-width: 68%;
  //               z-index: 1;
  //               overflow: hidden;
  //               text-overflow: ellipsis;
  //               display: -webkit-box; //作为弹性伸缩盒子模型显示。
  //               -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
  //               -webkit-line-clamp: 1; //显示的行
  //             }

  //             .btn {
  //               position: relative;
  //               z-index: 2;
  //               flex-shrink: 0;
  //               width: calc(62rem / 16);
  //               max-width: calc(62rem / 16);
  //               text-align: center;
  //               background-color: #eaad5f;
  //               border-radius: 5px;
  //               text-align: center;
  //               line-height: calc(25rem / 16);
  //               font-size: calc(14rem / 16);
  //               font-weight: 700;
  //               color: #fff;
  //             }
  //           }
  //         }

  //         >.btn {
  //           width: calc(62rem / 16);
  //           background-color: #eaad5f;
  //           border-radius: 5px;
  //           text-align: center;
  //           line-height: calc(25rem / 16);
  //           font-size: calc(14rem / 16);
  //           font-weight: 700;
  //           color: #fff;
  //           justify-content: flex-end;
  //         }
  //       }
  //     }
  //   }
  // }
}
</style>
